<!-- Tienda.vue -->
<template>
  <DefaultLayout>
    <div v-if="loading">
            <v-icon name="ri-loader-2-fill" animation="spin" class="icon" />
        </div>
    <div class="container">

      <h1>¡Bienvenido a nuestra sección de BootCamps!</h1>
      <p> Aquí tienes acceso a los bootcamps exclusivos de Ademass sobre las tecnologías más punteras.<br>
       <span>¿Listo para impulsar tu carrera en programación?</span> </p>
      <div class="cards">
        <div class="card" v-for="elm in bootcamps"  :key="elm?.id">
          <a :href="`/bootcamp-visor/${elm?.id}`">
            <img :src="elm?.image_url" alt="img" style="height: 300px;">
            <h4>{{ elm?.title }}</h4>
            <h4>{{ elm?.subtitle }}</h4>
            <h5>Acceder</h5>
          </a>
        </div>
       <div class="separator"></div>
       
        <div class="separator"></div>
        
      </div>
    </div>
  </DefaultLayout>
</template>
  
<script lang="ts" setup>
import DefaultLayout from '@/layouts/DefaultLayout.vue';
import BootCampService from '@/services/BootCampService';
import BootCampsI from '@/interfaces/BootCampInterface';
import { onMounted,ref,Ref } from 'vue';

const loading:Ref<boolean> = ref(false)
const service = new BootCampService
const bootcamps:Ref<BootCampsI[]> = ref([])

onMounted(async()=>{
  loading.value = true;
  bootcamps.value = await service.GetBootCamp()
  loading.value = false;
  
})
</script>
  
<style scoped lang="scss">
h1{
  margin-bottom: 10px;
}
.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100%;
  text-align: center;
  padding: 10px;
}
span{
  color: $secondary-color;
  font-weight: bold;
  
}
.separator{
  width: 15px;
}
.cards{
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
  padding: 20px;
  gap: 20px;
}

a {
  text-decoration: none;
}
a:hover{
  color: $secondary-color;
}

.card {
  margin-top: 20px;
  width: 300px;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  overflow: hidden;
  text-align: center;
}
.card:hover{
  scale: 1.01;
}

.card img {
  width: 100%;
  height: auto;
  border-bottom: 1px solid #ddd;
}

.card h4,
.card h5 {
  padding: 10px;
  margin: 0;
}

.card h4 {
  font-size: 18px;
  color: $secondary-color;
  background-color: #f9f9f9;
  border-bottom: 1px solid #ddd;
}


.card h5 {
  font-size: 16px;
  color: $secondary-color;
}

h5:hover {
  cursor: pointer;
}
@media screen and (max-width:600px){
  .cards{
    flex-direction: column;
  }

}</style>
  