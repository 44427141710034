<template>
    <!-- Main Menu -->
    <div :class="{ 'animate': true, 'container': expand, 'min-container': collapse }" style="overflow-y: scroll;"
        v-if="windowWidth > 950" @mouseenter="handleExpand" @mouseleave="handleCollapse">
        <div style="display: flex; justify-content: center; align-items: center; flex-direction: column; z-index: 10;" v-if="collapse">
            <v-icon name="ri-menu-unfold-fill" scale="1" fill="white"></v-icon>
            <small style="color: white; font-size: 10px;">MENÚ</small>
        </div>
        <div v-if="expand">
            <div class="spacer"></div>
            <h5 v-if="expand">General</h5>
            <hr v-else />
            <nav>
                <ul>
                    <li>
                        <RouterLink :to="{ name: 'home' }" class="link"><v-icon name="md-home-sharp" class="icon"
                                hover="true" animation="wrench" /> <span v-if="expand">Inicio</span>
                        </RouterLink>
                    </li>
                    <li>
                        <a href="https://ademass.com" target="_blank" class="link"><v-icon name="ai-ads" class="icon"
                                hover="true" animation="wrench" /> <span v-if="expand">Conoce Ademass</span>
                        </a>
                    </li>
                    <li>
                        <RouterLink :to="{ name: 'roadmaps' }" class="link"><v-icon name="md-route-twotone" class="icon"
                                hover="true" animation="wrench" /> <span v-if="expand">Hojas de ruta</span>
                        </RouterLink>
                    </li>
                    <li>
                        <RouterLink :to="{ name: 'courses' }" class="link"><v-icon name="gi-teacher" class="icon"
                                hover="true" animation="wrench" /> <span v-if="expand">Cursos</span></RouterLink>
                    </li>
                    <li>
                        <RouterLink :to="{ name: 'certifications' }" class="link"><v-icon name="gi-diploma" class="icon"
                                hover="true" animation="wrench" /> <span v-if="expand">Certificaciones</span>
                        </RouterLink>
                    </li>
                    <li>
                        <a href="https://ademass.com/crm/forms/ticket?styled=1&with_logo=1" class="link" target="_blank">
                            <v-icon name="md-supportagent-twotone" class="icon" hover="true" animation="wrench" />
                            <span v-if="expand">Soporte técnico</span>
                        </a>
                    </li>
                </ul>
            </nav>
            <div class="spacer"></div>
            <div>
                <h5>Novedades</h5>
                <nav>
                    <ul> 
                        <li>
                            <RouterLink :to="{ name: 'bookspdf' }" class="link"><v-icon name="bi-book-half"
                                    class="icon" hover="true" animation="wrench"></v-icon> <span
                                    v-if="expand">Manuales</span>
                            </RouterLink>
                        </li>
                     
                        <li>
                            <RouterLink :to="{ name: 'ejercicios' }" class="link"><v-icon name="md-librarybooks-round"
                                    class="icon" hover="true" animation="wrench"></v-icon> <span
                                    v-if="expand">Ejercicios</span>
                            </RouterLink>
                        </li>            

                    </ul>
                </nav>
            </div>
            
            <!--
            <div>
                <h5>Ademass Market</h5>
                <nav>
                    <ul>
                           <li>
                            <RouterLink :to="{ name: 'teacher' }" class="link" @click="storeMenu.toggleMenu"><v-icon
                                    name="gi-teacher" class="icon" hover="true" animation="wrench" /> Clases En Vivo
                            </RouterLink>
                        </li>
                        <li>
                            <RouterLink :to="{ name: 'bookshop' }" class="link" @click="storeMenu.toggleMenu"><v-icon
                                    name="bi-book-fill" class="icon" hover="true" animation="wrench" /> Librería
                            </RouterLink>
                        </li>
                        <li>
                            <RouterLink :to="{ name: 'shop' }" class="link" @click="storeMenu.toggleMenu"><v-icon
                                    name="hi-shopping-cart" class="icon" hover="true" animation="wrench" /> Tienda
                            </RouterLink>
                        </li>

                    </ul>
                </nav>
            </div>
            -->
            <div class="spacer"></div>

            <div>
                <h5 v-if="expand">Foro</h5>
                <hr v-else />
                <nav>
                    <ul>
                        <li>
                            <RouterLink :to="{ name: 'forum' }" class="link"><v-icon name="bi-question-circle-fill"
                                    class="icon" hover="true" animation="wrench" /> <span v-if="expand">Dudas y
                                    Preguntas</span>
                            </RouterLink>
                        </li>
                        <li v-if="store.token">
                            <RouterLink :to="{ name: 'my-questions' }" class="link"><v-icon name="si-dialogflow"
                                    class="icon" hover="true" animation="wrench" /> <span v-if="expand">Mis Preguntas</span>
                            </RouterLink>
                        </li>
                        <li>
                            <RouterLink :to="{ name: 'forum-latest-questions' }" class="link"><v-icon
                                    name="md-lineweight-twotone" class="icon" hover="true" animation="wrench" /> <span
                                    v-if="expand">Últimas Preguntas</span>
                            </RouterLink>
                        </li>
                    </ul>
                </nav>
            </div>
            <div class="spacer"></div>
            <div>
                <h5 v-if="expand">Empleo</h5>
                <hr v-else />
                <nav>
                    <ul>
                        <li>
                            <RouterLink :to="{ name: 'job-offering' }" class="link"><v-icon name="md-workhistory"
                                    class="icon" hover="true" animation="wrench"></v-icon> <span v-if="expand">Ofertas de
                                    Empleo</span>
                            </RouterLink>
                        </li>
                        <li v-if="store.token">
                            <RouterLink :to="{ name: 'job-profile' }" class="link"><v-icon name="bi-person-workspace"
                                    class="icon" hover="true" animation="wrench"></v-icon> <span v-if="expand">Mi Perfil de
                                    Empleo</span></RouterLink>
                        </li>
                        <li v-if="store.token">
                            <RouterLink :to="{ name: 'my-candidatures' }" class="link"><v-icon name="gi-tabletop-players"
                                    class="icon" hover="true" animation="wrench"></v-icon> <span v-if="expand">Mis
                                    Candidaturas</span></RouterLink>
                        </li>

                    </ul>
                </nav>
            </div>
            <div class="spacer"></div>
            <div v-if="store.token">
                <h5 v-if="expand">Social</h5>
                <hr v-else />
                <nav>
                    <ul>
                        <li>
                            <RouterLink :to="{ name: 'my-wall' }" class="link"><v-icon name="si-coderwall" class="icon"
                                    hover="true" animation="wrench" /> <span v-if="expand">Mi Muro</span>
                            </RouterLink>
                        </li>
                        <li>
                            <RouterLink :to="{ name: 'hall-of-fame' }" class="link"><v-icon name="md-stars-round"
                                    class="icon" hover="true" animation="wrench" /> <span v-if="expand">Salón De La
                                    Fama</span>
                            </RouterLink>
                        </li>
                        <li>
                            <RouterLink :to="{ name: 'messages' }" class="link"><v-icon name="ri-message-2-fill"
                                    class="icon" hover="true" animation="wrench" /> <span v-if="expand">Mensajería</span>
                            </RouterLink>
                        </li>
                        <li>
                            <RouterLink :to="{ name: 'friends' }" class="link"><v-icon name="fa-user-friends" class="icon"
                                    hover="true" animation="wrench" /> <span v-if="expand">Mis Amigos</span></RouterLink>
                        </li>
                        <li>
                            <RouterLink :to="{ name: 'social-profile-edit' }" class="link"><v-icon name="fa-user-edit"
                                    class="icon" hover="true" animation="wrench" /> <span v-if="expand">Gestionar Mi
                                    Canal</span></RouterLink>
                        </li>
                        <li>
                            <RouterLink :to="{ name: 'my-reputation' }" class="link"><v-icon name="ri-medal-fill"
                                    class="icon" hover="true" animation="wrench" /> <span v-if="expand">Mi Reputación
                                    Dev</span></RouterLink>
                        </li>
                        <li>
                            <a href="https://discord.com/invite/D6CTQyrf9Q" target="blank" class="link"
                                @click="storeMenu.toggleMenu"><v-icon name="bi-discord" class="icon" hover="true"
                                    animation="wrench" /> <span v-if="expand">Cafetería del Campus</span>
                            </a>
                        </li>
                    </ul>
                </nav>
            </div>
            <div class="spacer"></div>
            <div v-if="store.token">
                <h5 v-if="expand">Mi sección</h5>
                <hr v-else />
                <nav>
                    <ul>
                        <li>
                            <RouterLink :to="{ name: 'my-roadmaps' }" class="link"><v-icon name="md-route-twotone"
                                    class="icon" hover="true" animation="wrench" /> <span v-if="expand">Mis Hojas de
                                    Ruta</span></RouterLink>
                        </li>
                        <li>
                            <RouterLink :to="{ name: 'my-courses' }" class="link"><v-icon name="gi-teacher" class="icon"
                                    hover="true" animation="wrench" /> <span v-if="expand">Mis Cursos</span>
                            </RouterLink>
                        </li>
                        <li>
                            <RouterLink :to="{ name: 'my-diplomas' }" class="link"><v-icon name="gi-diploma" class="icon"
                                    hover="true" animation="wrench" /> <span v-if="expand">Mis Diplomas</span>
                            </RouterLink>
                        </li>
                        <li>
                            <RouterLink :to="{ name: 'my-notes' }" class="link"><v-icon name="md-notes" class="icon"
                                    hover="true" animation="wrench" /> <span v-if="expand">Mis Apuntes</span></RouterLink>
                        </li>
                        <li>
                            <RouterLink :to="{ name: 'my-favs' }" class="link"><v-icon name="md-playlesson-round"
                                    class="icon" hover="true" animation="wrench" /> <span v-if="expand">Mis Lecciones
                                    Favoritas</span></RouterLink>
                        </li>
                        <li>
                            <RouterLink :to="{ name: 'my-laters' }" class="link"><v-icon name="md-watchlater" class="icon"
                                    hover="true" animation="wrench" /> <span v-if="expand">Ver Más Tarde</span>
                            </RouterLink>
                        </li>
                        <li>
                            <RouterLink :to="{ name: 'codebites-saved' }" class="link"><v-icon name="bi-file-code-fill"
                                    class="icon" hover="true" animation="wrench" /> <span v-if="expand">CodeBites
                                    Guardados</span>
                            </RouterLink>
                        </li>
                        <li>
                            <RouterLink :to="{ name: 'codeposts-saved' }" class="link"><v-icon name="bi-file-post"
                                    class="icon" hover="true" animation="wrench" /> <span v-if="expand">CodePosts
                                    Guardados</span>
                            </RouterLink>
                        </li>
                        <li>
                            <RouterLink :to="{ name: 'profile' }" class="link"><v-icon name="la-user-circle" class="icon"
                                    hover="true" animation="wrench" /> <span v-if="expand">Mi Perfil</span>
                            </RouterLink>
                        </li>
                        <li>
                            <RouterLink to="#" class="link" @click.prevent="handleLogout"><v-icon name="la-power-off-solid"
                                    class="icon" hover="true" animation="wrench" /> <span v-if="expand">Cerrar Sesión</span>
                            </RouterLink>
                        </li>
                    </ul>
                </nav>
                <div class="spacer"></div>
                <div class="spacer"></div>
                <div class="spacer"></div>
                <div class="spacer"></div>
                <div class="spacer"></div>
                <div class="spacer"></div>
            </div>
            <div class="no-auth" v-else>
                <AuthBtn v-if="expand" />
            </div>
        </div>
    </div>

    <!-- Mobile Menu -->
    <div :class="{ animate: true, isOpen: storeMenu.isOpen, isClose: !storeMenu.isOpen }" v-if="windowWidth <= 950">
        <div class="spacer"></div>
        <div v-if="store.token">
            <h5 class="primary"><br />{{ store.email }}<br /></h5>
            <div class="spacer"></div>
        </div>
        <h5>General</h5>
        <nav>
            <ul>
                <li>
                    <RouterLink :to="{ name: 'home' }" class="link" @click="storeMenu.toggleMenu"><v-icon
                            name="md-home-sharp" class="icon" hover="true" animation="wrench" /> Inicio
                    </RouterLink>
                </li>
                <li>
                    <a href="https://ademass.com" target="_blank" class="link" @click="storeMenu.toggleMenu"><v-icon
                            name="ai-ads" class="icon" hover="true" animation="wrench" /> Conoce Ademass
                    </a>
                </li>
                <li>
                    <RouterLink :to="{ name: 'roadmaps' }" class="link" @click="storeMenu.toggleMenu"><v-icon
                            name="md-route-twotone" class="icon" hover="true" animation="wrench" /> Hojas de Ruta
                    </RouterLink>
                </li>
                <li>
                    <RouterLink :to="{ name: 'courses' }" class="link" @click="storeMenu.toggleMenu"><v-icon
                            name="co-education" class="icon" hover="true" animation="wrench" /> Cursos</RouterLink>
                </li>
                <li>
                    <RouterLink :to="{ name: 'certifications' }" class="link" @click="storeMenu.toggleMenu"><v-icon
                            name="gi-diploma" class="icon" hover="true" animation="wrench" /> Certificaciones
                    </RouterLink>
                </li>
                <li>
                    <a href="https://ademass.com/crm/forms/ticket?styled=1&with_logo=1" class="link" target="_blank">
                        <v-icon name="md-supportagent-twotone" class="icon" hover="true" animation="wrench" />
                        <span>Soporte técnico</span>
                    </a>
                </li>
            </ul>
        </nav>
        <div class="spacer"></div>

        <h5>Novedades</h5>
            <nav>
                <ul>
                    <li>
                        <RouterLink :to="{ name: 'bookspdf' }" class="link" @click="storeMenu.toggleMenu"><v-icon
                                name="bi-book-half" class="icon" hover="true" animation="wrench"></v-icon>Manuales</RouterLink>
                    </li>
                    <li>
                        <RouterLink :to="{ name: 'ejercicios' }" class="link" @click="storeMenu.toggleMenu"><v-icon
                                name="md-librarybooks-round" class="icon" hover="true" animation="wrench"></v-icon> Ejercicios</RouterLink>
                    </li>
                         
                </ul>
            </nav>
            
        <div class="spacer"></div>
        <div>
            <h5>Foro</h5>
            <nav>
                <ul>
                    <li>
                        <RouterLink :to="{ name: 'forum' }" class="link" @click="storeMenu.toggleMenu"><v-icon
                                name="bi-question-circle-fill" class="icon" hover="true" animation="wrench" /> Dudas y
                            Preguntas
                        </RouterLink>
                    </li>
                    <li v-if="store.token">
                        <RouterLink :to="{ name: 'my-questions' }" class="link" @click="storeMenu.toggleMenu"><v-icon
                                name="si-dialogflow" class="icon" hover="true" animation="wrench" /> Mis Preguntas
                        </RouterLink>
                    </li>
                    <li>
                        <RouterLink :to="{ name: 'forum-latest-questions' }" class="link" @click="storeMenu.toggleMenu">
                            <v-icon name="md-lineweight-twotone" class="icon" hover="true" animation="wrench" /> Últimas
                            Preguntas
                        </RouterLink>
                    </li>
                </ul>
            </nav>
        </div>
        <div class="spacer"></div>
        <div>
            <h5>Empleo</h5>
            <nav>
                <ul>
                    <li>
                        <RouterLink :to="{ name: 'job-offering' }" class="link" @click="storeMenu.toggleMenu"><v-icon
                                name="md-workhistory" class="icon" hover="true" animation="wrench"></v-icon> Ofertas de
                            Empleo</RouterLink>
                    </li>
                    <li v-if="store.token">
                        <RouterLink :to="{ name: 'job-profile' }" class="link" @click="storeMenu.toggleMenu"><v-icon
                                name="bi-person-workspace" class="icon" hover="true" animation="wrench"></v-icon> Mi Perfil
                            de Empleo</RouterLink>
                    </li>
                    <li v-if="store.token">
                        <RouterLink :to="{ name: 'my-candidatures' }" class="link" @click="storeMenu.toggleMenu"><v-icon
                                name="gi-tabletop-players" class="icon" hover="true" animation="wrench"></v-icon> Mis
                            Candidaturas</RouterLink>
                    </li>
                </ul>
            </nav>
        </div>
        <div class="spacer"></div>
        <div v-if="store.token">
            <h5>Social</h5>
            <nav>
                <ul>
                    <li>
                        <RouterLink :to="{ name: 'my-wall' }" class="link" @click="storeMenu.toggleMenu"><v-icon
                                name="si-coderwall" class="icon" hover="true" animation="wrench" /> Mi Muro
                        </RouterLink>
                    </li>
                    <li>
                        <RouterLink :to="{ name: 'hall-of-fame' }" class="link" @click="storeMenu.toggleMenu"><v-icon
                                name="md-stars-round" class="icon" hover="true" animation="wrench" /> Salón De La Fama
                        </RouterLink>
                    </li>
                    <li>
                        <RouterLink :to="{ name: 'messages' }" class="link" @click="storeMenu.toggleMenu"><v-icon
                                name="ri-message-2-fill" class="icon" hover="true" animation="wrench" /> Mensajería
                        </RouterLink>
                    </li>
                    <li>
                        <RouterLink :to="{ name: 'friends' }" class="link" @click="storeMenu.toggleMenu"><v-icon
                                name="fa-user-friends" class="icon" hover="true" animation="wrench" /> Mis Amigos
                        </RouterLink>
                    </li>
                    <li>
                        <RouterLink :to="{ name: 'social-profile-edit' }" class="link" @click="storeMenu.toggleMenu"><v-icon
                                name="fa-user-edit" class="icon" hover="true" animation="wrench" /> Gestionar Mi Canal
                        </RouterLink>
                    </li>
                    <li>
                        <RouterLink :to="{ name: 'my-reputation' }" class="link" @click="storeMenu.toggleMenu"><v-icon
                                name="ri-medal-fill" class="icon" hover="true" animation="wrench" /> Mi Reputación Dev
                        </RouterLink>
                    </li>
                    <li>
                        <a href="https://discord.com/invite/D6CTQyrf9Q" target="blank" class="link"
                            @click="storeMenu.toggleMenu"><v-icon name="bi-discord" class="icon" hover="true"
                                animation="wrench" /> Cafetería del Campus
                        </a>
                    </li>
                </ul>
            </nav>
        </div>
        <div class="spacer" />
        <div v-if="store.token">
            <h5>Mi sección</h5>
            <nav>
                <ul>
                    <li>
                        <RouterLink :to="{ name: 'my-roadmaps' }" class="link" @click="storeMenu.toggleMenu"><v-icon
                                name="md-route-twotone" class="icon" hover="true" animation="wrench" /> Mis Hojas de
                            Ruta</RouterLink>
                    </li>
                    <li>
                        <RouterLink :to="{ name: 'my-courses' }" class="link" @click="storeMenu.toggleMenu"><v-icon
                                name="co-education" class="icon" hover="true" animation="wrench" /> Mis Cursos
                        </RouterLink>
                    </li>
                    <li>
                        <RouterLink :to="{ name: 'my-diplomas' }" class="link" @click="storeMenu.toggleMenu"><v-icon
                                name="gi-diploma" class="icon" hover="true" animation="wrench" /> Mis Diplomas
                        </RouterLink>
                    </li>
                    <li>
                        <RouterLink :to="{ name: 'my-notes' }" class="link" @click="storeMenu.toggleMenu"><v-icon
                                name="md-notes" class="icon" hover="true" animation="wrench" /> Mis Apuntes</RouterLink>
                    </li>
                    <li>
                        <RouterLink :to="{ name: 'my-favs' }" class="link" @click="storeMenu.toggleMenu"><v-icon
                                name="md-playlesson-round" class="icon" hover="true" animation="wrench" /> Mis Lecciones
                            Favoritas</RouterLink>
                    </li>
                    <li>
                        <RouterLink :to="{ name: 'my-laters' }" class="link" @click="storeMenu.toggleMenu"><v-icon
                                name="md-watchlater" class="icon" hover="true" animation="wrench" /> Ver Más Tarde
                        </RouterLink>
                    </li>
                    <li>
                        <RouterLink :to="{ name: 'codebites-saved' }" class="link" @click="storeMenu.toggleMenu"><v-icon
                                name="bi-file-code-fill" class="icon" hover="true" animation="wrench" /> CodeBites Guardados
                        </RouterLink>
                    </li>
                    <li>
                        <RouterLink :to="{ name: 'codeposts-saved' }" class="link" @click="storeMenu.toggleMenu"><v-icon
                                name="bi-file-post" class="icon" hover="true" animation="wrench" /> CodePosts Guardados
                        </RouterLink>
                    </li>
                    <li>
                        <RouterLink :to="{ name: 'profile' }" class="link" @click="storeMenu.toggleMenu"><v-icon
                                name="la-user-circle" class="icon" hover="true" animation="wrench" /> Mi Perfil
                        </RouterLink>
                    </li>
                    <li>
                        <RouterLink to="#" class="link" @click.prevent="handleLogout"><v-icon name="la-power-off-solid"
                                class="icon" hover="true" animation="wrench" /> Cerrar Sesión
                        </RouterLink>
                    </li>
                </ul>
            </nav>
        </div>
        <div class="no-auth" v-else>
            <AuthBtn @click="storeMenu.toggleMenu" />
            <div class="spacer" />
        </div>
</div>
</template>

<script lang="ts" setup>
import { useAuth } from '@/store/useAuth'
import AuthBtn from '../buttons/AuthBtn.vue'
import { useMenu } from '@/store/useMenu'
import useWindowWidth from '@/composables/useWindowWidth'
import router from '@/router'
import { ref, onMounted } from 'vue'
import ProfileService from '@/services/ProfileService'

const store = useAuth()
const storeMenu = useMenu()
const { windowWidth } = useWindowWidth()
const collapse = ref(true)
const expand = ref(false)
const userId = ref('')
const userFull = ref({})
const profileService = new ProfileService()
const loading = ref(true)


onMounted(async () => {
    loading.value = true

    if (!store.token) {
        loading.value = false
        return
    }

    userFull.value = await profileService.MyUser(store.token)
    userId.value = userFull.value.id
    loading.value = false
})

const handleCollapse = () => {
    collapse.value = true
    expand.value = false
}

const handleExpand = () => {
    collapse.value = false
    expand.value = true
}

const handleLogout = () => {
    storeMenu.isOpen = false
    store.logout()
    router.push({ name: 'auth' })
}
</script>

<style lang="scss" scoped>
.discord {
    background-color: #5561ea;
    border-radius: 10px;
    color: white;
}

.isOpen {
    background-color: $primary-color;
    display: block;
    position: absolute;
    width: 100vw;
    z-index: 10;
    border-bottom: 5px solid $secondary-color;
}

.isClose {
    display: none;
}

.no-auth {
    padding-left: 10px;
}

h5 {
    text-transform: uppercase;
    padding-left: 20px;
}

.icon {
    margin-right: 5px;
}

.primary {
    color: $text-color;
}

.container {
    height: 100svh;
    min-width: 250px;
    position: fixed;
    background-color: white;
    z-index: 999;
    border-top-right-radius: 10px;
    border-bottom-right-radius: 10px;
    box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.4);
    animation: 0.5s ease-out 0s 1 leftIn;
}

.min-container {
    z-index: 999;
    overflow-y: hidden !important;
    margin-top: 50px;
    height: fit-content;
    min-width: 50px;
    background-color: #333;
    border-top-right-radius: 10px;
    border-bottom-right-radius: 10px;
    padding: 10px;
    box-shadow: 2px 2px 5px #ccc;

    a svg {
        fill: white;
    }

    position: fixed;
}

nav {
    height: 100%;
}

ul {
    display: flex;
    flex-direction: column;
}

@keyframes leftIn {
    0% {
        transform: translateX(-50%);
        opacity: 0;
    }

    100% {
        transform: translateY(0);
        opacity: 1;
    }
}

.link {
    text-decoration: none;
    padding: 10px;
    margin: 5px;
    display: flex;
    overflow-y: hidden;
}

.link:hover {
    background-color: $secondary-color;
    border-radius: 10px;
    color: white;

    span {
        color: white;
    }

    svg {
        fill: white;
    }
}
</style>